<template>
  <div>
    <RMPageHeader>
      <template v-slot:header>
        {{ $t('menu.whoWeAre') }}
      </template>
    </RMPageHeader>
    <div class="row p-b-10">
      <div class="col-lg-4">
        <h4 class="mb-3">{{ $t("about.ourStory") }}</h4>
        <p>{{ $t("about.ourStoryText") }}</p>
      </div>
      <div class="col-lg-4">
        <h4 class="mb-3">{{ $t("about.ourVision") }}</h4>
        <p>{{ $t("about.ourVisionText") }}</p>
      </div>
      <div class="col-lg-4">
        <h4 class="mb-3">{{ $t("about.followUs") }}</h4>
        <p>
          <a :href="facebookLink"><img src="@/assets/images/fb_01.webp" width="8%" class="m-r-10" alt="Facebook"/></a>
          <a v-if="instagramLink !== ''" :href="instagramLink"><img src="@/assets/images/instagram_01.webp" width="8%" alt="Instagram"/></a>
        </p>
      </div>
    </div>
    <div class="row bg-light text-center p-40">
      <div class="col-lg-4">
        <div class="rm-milestone-number">
          {{ this.numRegisteredUsers }}
        </div>
        <div class="rm-milestone-text">
          {{ $t("about.numRegisteredUsers") }}
        </div>
      </div>
      <div class="col-lg-4">
        <div class="rm-milestone-number">
          {{ this.numGames }}
        </div>
        <div class="rm-milestone-text">
          {{ $t("about.numGames") }}
        </div>
      </div>
      <div class="col-lg-4">
        <div class="rm-milestone-number">
          {{ this.numCompletedGames }}
        </div>
        <div class="rm-milestone-text">
          {{ $t("about.numCompletedGames") }}
        </div>
      </div>
    </div>

    <div class="row p-t-20">
      <div class="col">
        <h4 class="mb-3">{{ $t("about.contactUs") }}</h4>
        <p v-html="contactUsString">
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {adminService} from "@/_services/admin.service";
import {getAllGames} from "@/_helpers/games_helper";
import {getDomain} from "@/_helpers/domain_helper";
import RMPageHeader from "@/components/common/RMPageHeader.vue";

export default {
  name: "About",
  components: {RMPageHeader},
  data() {
    return {
      numRegisteredUsers: 0,
      numGames: Object.keys(getAllGames()).length,
      numCompletedGames: 0,
    };
  },
  computed: {
    domain() {
      return getDomain();
    },
    facebookLink() {
      if(this.domain === "resi-me.si"){
        return "https://www.facebook.com/wwwresimesi"
      }
      return "https://www.facebook.com/cyphiocom";
    },
    instagramLink() {
      if(this.domain === "resi-me.si"){
        return "";
      }
      return "https://www.instagram.com/cyphiogames"
    },
    infoEmail() {
      if(this.domain === "resi-me.si"){
        return "info@resi-me.si";
      }
      return "info@cyphio.com";
    },
    contactUsString() {
      let baseStr = this.$t("about.haveQuestions");
      return baseStr.replace("{0}", `<a href="mailto:${this.infoEmail}">${this.infoEmail}</a>`);
    }
  },
  created() {
    adminService.getPageAbout().then((data) => {
      this.numRegisteredUsers = data.numRegisteredUsers;
      this.numCompletedGames = data.numCompletedGames;
    });
  }
};
</script>

<style scoped>
.no-border {
  border: none;
  box-shadow: none;
}

.about-info {
  margin-top: 20px;
}

.about-image {
  text-align: center;
}

.about-name {
  text-align: center;
}

.about-rank {
  text-align: center;
}

.about-description {
  margin-top: 20px;
  text-align: center;
}
</style>
